<template>
  <div class="desktop-header" :class="headerClassList">
    <div class="desktop-header__gap-filler"></div>
    <div class="desktop-header__content-container">
      <a-header-logo class="desktop-header__logo" />

      <a-header-menu :menu-list="menuList" class="desktop-header__menu" />

      <a-visibility hide :on="[$breakpoint.desktopMd]">
        <client-only>
          <a-header-actions class="desktop-header__actions" />
        </client-only>
      </a-visibility>
    </div>
    <i />
    <a-visibility show :on="[$breakpoint.desktopMd]">
      <client-only>
        <a-header-actions class="desktop-header__actions" />
      </client-only>
    </a-visibility>
  </div>
</template>

<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { mapGetters } from 'vuex'
import AHeaderMenu from './AHeaderMenu'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { hydrateWhenIdle } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

export default {
  name: 'AHeaderDesktop',
  mixins: [hydrationHelpers],
  components: {
    AHeaderLogo: hydrateWhenIdle(() => import('./AHeaderLogo/index')),
    AHeaderActions: () => import('../Shared/AHeaderActions'),
    AHeaderMenu
  },
  props: {
    menuList: propValidator([PROP_TYPES.ARRAY])
  },
  computed: {
    ...mapGetters({
      isHeaderCollapsed: 'isHeaderCollapsed',
      isOnboardingInProgress: 'onboarding/isOnboardingInProgress'
    }),
    headerClassList() {
      return {
        'desktop-header_collapsed':
          !this.isOnboardingInProgress && this.isHeaderCollapsed
      }
    }
  },
  mounted() {
    this.$_hydrationHelpers_showScroll()
    this.$emit('mounted')
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/local/mixins/grid';
.desktop-header {
  width: 100%;
  height: $size-header-expanded;
  color: white;

  @include page-layout;
  @include page-paddings;

  padding-top: 0;
  padding-bottom: 0;

  grid-template-rows: 50px;
  align-content: center;

  @include nthChild(1) {
    @include desktop-sm {
      grid-column: 1 / 3;
    }
  }

  @include desktop-sm {
    grid-template-columns: minmax(0, 100%);
  }

  /*
   * IMPORTANT!
   * Modify with caution, since there is JS code that rely on this value.
   * Example: onboarding flow restarts with a delay that considers this value.
   */
  transition: height 0.5s;

  .desktop-header__gap-filler {
    @include top-gap-filler(
      $background: linear-gradient(to right, #79b0ea, #5356d4),
      $z-index: 1
    );
  }

  .desktop-header__content-container {
    display: flex;
    align-items: center;
  }

  $margin: 50px;

  /deep/ .desktop-header__logo {
    width: 140px;
    min-width: 140px;
    height: 63px;
    display: flex;
    justify-content: flex-start;

    @include desktop-sm {
      width: 32px;
      min-width: 32px;
      height: 46px;
    }
  }

  .desktop-header__menu {
    margin-left: $margin;
    margin-right: $margin;
  }

  .desktop-header__actions {
    $margin-left: (
      $above-desktop-xl: auto,
      $desktop-lg: 0,
      $desktop-sm: auto
    );
    @include setStyleByBreakpoint('margin-left', $margin-left);
  }
}

.desktop-header.desktop-header_collapsed {
  height: $size-header-collapsed;
}
</style>
